import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/diy.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistdiy.json"


const DiyPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-primary"}
    path={"diy"}
    bannerimg={imgbanner}
    title={allcategories.diy.title}
    writeup={allcategories.diy.description} 
    videolist={videolist} />
)

export default DiyPage
